<template>
    <Layout>
        <h4>New Title Deed Request</h4>
        <form
            class="form-horizontal vld-parent"
            @submit.prevent="requestTitleDeed"
            ref="formContainer"
        >
            <div class="row">
                <div class="col-lg-12">
                    <div class="form-group">
                        <label for="ownerfname">Unit</label>
                        <b-form-select
                            id="ownerfname"
                            name="ownerfname"
                            v-model="titleDeed.unit_id"
                            :options="mappedUserUnits"
                            @change="checkPayment(titleDeed.unit_id)"
                            :class="{
                                'is-invalid':
                                    submitted && $v.titleDeed.unit_id.$error,
                            }"
                        ></b-form-select>

                        <div
                            v-if="submitted && $v.titleDeed.unit_id.$error"
                            class="invalid-feedback"
                        >
                            <span v-if="!$v.titleDeed.unit_id.required"
                                >Unit is required</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" v-if="payment == 2">
                <div class="col-lg-6">
                    <label for="ownerfname">Bank Release Letter</label>
                    <b-form-file
                        placeholder="Choose File"
                        drop-placeholder="Drop file here..."
                        @change="handleReleaseLetter"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid':
                                submitted &&
                                $v.titleDeed.bank_release_letter.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="
                            submitted && $v.titleDeed.bank_release_letter.$error
                        "
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.titleDeed.bank_release_letter.required"
                            >Release letter is required</span
                        >
                    </div>
                </div>

                <div class="col-lg-6">
                    <label for="ownerfname">Bank NOC</label>
                    <b-form-file
                        placeholder="Choose File"
                        drop-placeholder="Drop file here..."
                        @change="handleNoc"
                        class="mb-3"
                        accept="image/png, image/gif, image/jpeg,application/pdf"
                        :class="{
                            'is-invalid':
                                submitted && $v.titleDeed.bank_noc.$error,
                        }"
                    ></b-form-file>
                    <div
                        v-if="submitted && $v.titleDeed.bank_noc.$error"
                        class="invalid-feedback"
                    >
                        <span v-if="!$v.titleDeed.bank_noc.required"
                            >Bank Noc is required</span
                        >
                    </div>
                </div>
            </div>
            <submitButton />
        </form>
        <paymentModal
            ref="paymentWindow"
            :modalTitle="modalTitle"
            :requestId="requestId"
            :referenceNumber="refernceNumber"
            page="Title Deed"
            :adminFees="adminFees"
            :adminVatFees="adminVatFees"
            :serviceFees="serviceFees"
            :securityDepFees="securityDepFees"
            :urgentFees="urgentFees"
        />
    </Layout>
</template>
<script>
import Layout from "../../layouts/main.vue";
import { BASE_URL, REQUEST_TYPES } from "../../common";
import axios from "axios";
import alertMixin from "../../../mixins/alertMixin";
import { required, requiredIf } from "vuelidate/lib/validators";
import paymentModal from "../payment/paymentModal.vue";
import submitButton from "../../../components/submitButton.vue";

export default {
    mixins: [alertMixin],

    validations: {
        titleDeed: {
            unit_id: { required },
            bank_release_letter: {
                required: requiredIf(function () {
                    return this.payment == 2;
                }),
            },

            bank_noc: {
                required: requiredIf(function () {
                    return this.payment == 2;
                }),
            },
        },
    },
    data() {
        return {
            requestId: "",
            refernceNumber: "",
            submitted: false,
            fullPage: false,
            isUrgent: false,
            adminFees: "",
            adminVatFees: "",
            securityDepFees: "",
            urgentFees: "",
            serviceFees: "",
            modalTitle: "",
            titleDeed: {
                unit_id: "",
                bank_release_letter: "",
                bank_release_letter_filename: "",
                bank_noc: "",
                bank_noc_filename: "",
            },
            payment: "",
        };
    },
    mounted() {
        this.getTermsConditions();
    },
    computed: {
        userUnits() {
            return this.$store.getters["apidata/getUserInfo"];
            // return JSON.parse(localStorage.user_info).units;
        },
        mappedUserUnits() {
            return this.userUnits?.units?.map((ele) => {
                return {
                    value: ele.unit_id,
                    text: ele.unit_no,
                    payment: ele.payment_type,
                };
            });
        },
    },
    components: {
        Layout,
        submitButton,
        paymentModal,
    },
    methods: {
        proceed(e) {
            console.log("payment initiated", e);
        },
        changeStatus() {
            console.log(this.isUrgent);
            this.isUrgent = !this.isUrgent;
        },
        handleReleaseLetter(e) {
            let reader = new FileReader();
            this.titleDeed.bank_release_letter_filename =
                e.target.files[0].name;
            reader.onload = (e) => {
                this.titleDeed.bank_release_letter =
                    e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },

        handleNoc(e) {
            let reader = new FileReader();
            this.titleDeed.bank_noc_filename = e.target.files[0].name;
            reader.onload = (e) => {
                this.titleDeed.bank_noc = e.target.result.split(",")[1];
            };
            reader.readAsDataURL(e.target.files[0]);
        },
        getTermsConditions() {
            if (localStorage.cs_user_token) {
                let payload = {
                    request_type: REQUEST_TYPES[5].id,
                };
                axios
                    .post(
                        `${BASE_URL}getrequeststermsconditions?token=${localStorage.cs_user_token}`,
                        payload
                    )
                    .then((res) => {
                        this.adminFees = Number(res.data.response.service_fees);
                        this.serviceFees = Number(
                            res.data.response.service_fees
                        );
                        this.urgentFees = res.data.response.urgent_fees;
                        this.adminVatFees = res.data.response.admin_vat_fees;
                        this.securityDepFees =
                            res.data.response.security_deposit;
                    });
            }
        },
        checkPayment(id) {
            let unit = this.mappedUserUnits.filter((ele) => ele.value == id);
            // console.log(unit);
            this.payment = unit[0].payment;
            console.log(this.payment);
            if (unit[0].payment == 3) {
                this.showError(
                    "You cannot apply for title deed for a unit under installments"
                );
                this.titleDeed.unit_id = "";
            }
        },
        requestTitleDeed() {
            this.submitted = true;
            this.$v.$touch();
            if (!this.$v.$error) {
                if (localStorage.cs_user_token) {
                    let loader = this.$loading.show({
                        container: this.fullPage
                            ? null
                            : this.$refs.formContainer,
                        canCancel: false,
                        onCancel: this.onCancel,
                    });
                    axios
                        .post(
                            `${BASE_URL}titledeeds?token=${localStorage.cs_user_token}`,
                            this.titleDeed
                        )
                        .then((res) => {
                            // this.resetFields();
                            console.log(res.data.response);
                            this.requestId = res.data.response.request_id;
                            this.refernceNumber = res.data.response.ref_no;
                            this.modalTitle =
                                "Payment for  " +
                                " " +
                                res.data.response.ref_no;
                            loader.hide();
                            // this.$bvModal.show("modal-backdrop-new");
                            this.$refs.paymentWindow.showModal();
                        })
                        // eslint-disable-next-line no-unused-vars
                        .catch((err) => {
                            loader.hide();
                        });
                }
            }
        },
    },
};
</script>
