var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('h4',[_vm._v("New Title Deed Request")]),_c('form',{ref:"formContainer",staticClass:"form-horizontal vld-parent",on:{"submit":function($event){$event.preventDefault();return _vm.requestTitleDeed.apply(null, arguments)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Unit")]),_c('b-form-select',{class:{
                            'is-invalid':
                                _vm.submitted && _vm.$v.titleDeed.unit_id.$error,
                        },attrs:{"id":"ownerfname","name":"ownerfname","options":_vm.mappedUserUnits},on:{"change":function($event){return _vm.checkPayment(_vm.titleDeed.unit_id)}},model:{value:(_vm.titleDeed.unit_id),callback:function ($$v) {_vm.$set(_vm.titleDeed, "unit_id", $$v)},expression:"titleDeed.unit_id"}}),(_vm.submitted && _vm.$v.titleDeed.unit_id.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.titleDeed.unit_id.required)?_c('span',[_vm._v("Unit is required")]):_vm._e()]):_vm._e()],1)])]),(_vm.payment == 2)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-6"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Bank Release Letter")]),_c('b-form-file',{staticClass:"mb-3",class:{
                        'is-invalid':
                            _vm.submitted &&
                            _vm.$v.titleDeed.bank_release_letter.$error,
                    },attrs:{"placeholder":"Choose File","drop-placeholder":"Drop file here...","accept":"image/png, image/gif, image/jpeg,application/pdf"},on:{"change":_vm.handleReleaseLetter}}),(
                        _vm.submitted && _vm.$v.titleDeed.bank_release_letter.$error
                    )?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.titleDeed.bank_release_letter.required)?_c('span',[_vm._v("Release letter is required")]):_vm._e()]):_vm._e()],1),_c('div',{staticClass:"col-lg-6"},[_c('label',{attrs:{"for":"ownerfname"}},[_vm._v("Bank NOC")]),_c('b-form-file',{staticClass:"mb-3",class:{
                        'is-invalid':
                            _vm.submitted && _vm.$v.titleDeed.bank_noc.$error,
                    },attrs:{"placeholder":"Choose File","drop-placeholder":"Drop file here...","accept":"image/png, image/gif, image/jpeg,application/pdf"},on:{"change":_vm.handleNoc}}),(_vm.submitted && _vm.$v.titleDeed.bank_noc.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.titleDeed.bank_noc.required)?_c('span',[_vm._v("Bank Noc is required")]):_vm._e()]):_vm._e()],1)]):_vm._e(),_c('submitButton')],1),_c('paymentModal',{ref:"paymentWindow",attrs:{"modalTitle":_vm.modalTitle,"requestId":_vm.requestId,"referenceNumber":_vm.refernceNumber,"page":"Title Deed","adminFees":_vm.adminFees,"adminVatFees":_vm.adminVatFees,"serviceFees":_vm.serviceFees,"securityDepFees":_vm.securityDepFees,"urgentFees":_vm.urgentFees}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }